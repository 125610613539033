@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
/*imports */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.dark-theme {
  background-color: #121212;
}

.dark-theme,
.black * {
  color: rgb(255, 255, 255);
}

html {
  scroll-behavior: smooth;
}

body {
  max-width: 100vw;
  overflow-x: hidden;
}

p {
  line-height: 1.5;
}

a {
  text-decoration: none;
}

ul {
  display: flex;
}

li {
  list-style-type: none;
}

.h1-t {
  color: royalblue;
}

h1 {
  font-size: 56px;
  color: #3ba000;
  margin-bottom: 24px;
  text-align: center;
  margin-top: 100px;
}

h2 {
  font-size: 32px;
  margin-bottom: 6vh;
  text-align: center;
  margin-top: 5vh;
}

button {
  cursor: pointer;
}

button:active {
  transform: translateY(1px);
}

.btn {
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #3ba000;
  color: white;
  padding: 12px 24px;
  font-size: 20px;
  border-radius: 4px;
  border: none;
  margin-top: 50px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border-radius: 10vh;
}

.purple {
  color: #3ba000;
}
.purple--d {
  color: #3ba000;
}

.logo {
  width: 90px;
  padding: 10px;
}

/* NavBar */
nav {
  height: 80px;
  display: flex;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);
  position: relative;
}

Nav {
  background-color: rgba(29, 23, 43, 0.13);
}

.nav_container {
  width: 100%;
  max-width: 1200px;
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.nav_link {
  text-decoration: none;
  font-size: 20px;
  font-weight: bold;
  padding: 8px;
  color: #3ba000;
  position: relative;
  transition: all 0.2s ease-in-out 0s;
}
.nav_link--dark {
  color: #3ba000;
}

.nav_link:hover {
  font-size: 21px;
}

.nav_link::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #3ba000;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.2s ease-in-out 0s;
}

.nav_link:hover::before {
  visibility: visible;
  transform: scaleX(1.2);
}

.nav_link--primary {
  border: 2px solid #3ba000;
  border-radius: 4px;
}

.nav_link--primary:hover {
  text-decoration: none;
}

.nav_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #3ba000;
  font-size: 20px;
  position: relative;
  margin-left: 8px;
}

.btn__menu {
  background-color: transparent;
  font-size: 32px;
  border: none;
  color: #3ba000;
  display: none;
}

.menu_backdrop {
  position: absolute;
  background-color: #242424;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  transition: all 300ms ease;
  opacity: 0;
  transform: translate(100%);
}

.menu--open {
  max-height: 100vh;
  overflow: hidden;
}

.menu--open .menu_backdrop {
  visibility: visible;
  opacity: 1;
  transform: translate(0);
}

.menu_links {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #3ba000;
  font-size: larger;
}

.menu_list {
  padding: 32px 0;
}

.menu_link {
  color: white;
  font-size: 40px;
}

.btn__menu--close {
  position: absolute;
  top: 10px;
  right: 20px;
  padding: 8px;
  color: white;
}

/* 
Landing/Header page
*/

#landing {
  background-color: rgba(29, 23, 43, 0.13);
}
.header__sub {
  text-align: center;
}

header {
  height: calc(100vh - 80px);
}

.header__container {
  width: 100%;
  max-width: 720px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.header__description {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header__img--wrapper {
  align-items: center;
  margin: 0 auto;
  max-width: 600px;
  scale: 2;
  max-height: 50vh;
  z-index: 50;
}

.container {
  padding: 6px 0;
}

.container .books__container {
  padding: 24px 0;
}

.row {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
}

.row__column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.no-cursor {
  cursor: not-allowed;
}

/* 
Highlight page
*/

#highlights {
  position: relative;
  max-width: 100vw;
}

.highlight__wrapper {
  display: flex;
  justify-content: center;
}

.highlight {
  padding: 0 24px;
  width: calc(100% / 3);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.highlight__para {
  text-align: center;
  max-width: 280px;
  align-items: center;
  padding-right: 12px;
  margin-bottom: 5vh;
}

.highlight__img {
  border: 2px solid #3ba000;
  color: #3ba000;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: 32px;
  margin-bottom: 24px;
}

.highlight__title {
  font-size: 20px;
  margin-bottom: 1vh;
  text-align: center;
  color: #3ba000;
  font-weight: bold;
}

.purple--h {
  padding-bottom: 10px;
  color: #3ba000;
}

/* 
Featured 
*/

#features {
  padding-top: 8vw;
  background-color: rgba(29, 23, 43, 0.13);
}

/* 
Explore page
*/

#explore {
  background-color: rgba(116, 65, 213, 0.1);
}

/* 
Contact Page
*/

.contact__header--title {
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 0px;
}

.contact__body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  background-color: rgba(29, 23, 43, 0.13);
}

/*Contact form*/
.contact-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
}

.form-group {
  width: 48%;
  margin-bottom: 10px;
}

.form-group label {
  display: block;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.form-control {
  width: 100%;
  height: 40px;
  border: 2px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  font-size: 16px;
  color: #333;
}

.form-control:focus {
  outline: none;
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-control::placeholder {
  color: #999;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  margin-top: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.btn-primary:hover {
  background-color: #0069d9;
  border-color: #0062cc;
  transition: all 0.3s ease;
}

@media (max-width: 768px) {
  .form-group {
    width: 100%;
  }
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

input[type="text"],
input[type="emai"],
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

textarea {
  height: 150px;
}

.contact__btn--ctc {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 1.2rem;
  color: #fff;
  background-color: #3ba000;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  margin: 20px auto 0px auto;
}

.contact__btn--ctc:hover {
  background-color: #0062cc;
}

.about__btn:hover {
  background-color: #3ba000;
}

/* 
Footer page
*/

.footer__logo--img {
  width: 100px;
  filter: grayscale(1) invert(1);
  margin-bottom: 30px;
  margin-top: 20px;
}

.footer__logo {
  display: flex;
}
footer {
  background-color: #242424;
  height: 32vh;
}

.footer__link {
  font-size: 22px;
  text-decoration: none;
  position: relative;
  color: #3ba000;
  padding: 0 8px;
  margin-bottom: 30px;
  font-weight: bold;
  flex-wrap: wrap;
}

.footer__link::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: -2px;
  left: 0;
  background-color: #3ba000;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.2s ease-in-out 0s;
}

.footer__link:hover::before {
  visibility: visible;
  transform: scaleX(0.4);
}

.footer__copyright {
  font-size: 22px;
  margin-top: 10px;
  color: #3ba000;
}

.footer_list {
}

/* 
Dashboard Page
*/

.dash__page {
  height: 61.5vh;
}

.dash__list {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.dash__links {
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #3ba000;
  color: white;
  padding: 12px 24px;
  font-size: 20px;
  border-radius: 4px;
  border: none;
  margin-top: 50px;
  width: 30%;
  height: 70px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border-radius: 10vh;
  display: flex;
}

.dash__links:hover {
  background-color: #0062cc;
}

.container {
  height: 40%;
}

.container__p {
  min-height: 80%;
}

/* 
Login Page
*/

.login__page {
  align-items: center;
  justify-content: center;
  height: 62vh;
  background-color: rgba(29, 23, 43, 0.13);
}

.login__title {
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 30px;
}

.login__wrapper {
  display: flex;
  justify-content: center;
}

.boxes {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.leftbox {
  color: #3ba000;
  padding: 20px;
  width: 100%;
  border-radius: 5px;
  font-size: 19px;
  line-height: 1.4;
  text-align: center;
}

.middlebox {
  color: #3ba000;
  padding: 20px;
  width: 100%;
  border-radius: 5px;
  font-size: 19px;
  line-height: 1.4;
  text-align: center;
}

.rightbox {
  color: #3ba000;
  padding: 20px;
  width: 100%;
  border-radius: 5px;
  font-size: 19px;
  line-height: 1.4;
  text-align: center;
}

.contact__btn--ctc {
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #3ba000;
  color: white;
  padding: 12px 24px;
  font-size: 20px;
  border-radius: 4px;
  border: none;
  margin-top: 50px;
  width: 20%;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border-radius: 10vh;
  position: relative;
}

.contact__btn--ctc:hover {
  animation: bounce 0.5s linear forwards;
}

.about__btn {
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #3ba000;
  color: white;
  padding: 12px 24px;
  font-size: 20px;
  border-radius: 4px;
  border: none;
  margin-top: 50px;
  margin-bottom: 50px;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border-radius: 10vh;
  position: relative;
}

.about__btn:hover {
  animation: bounce 0.5s linear forwards;
}

.login__btn {
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #3ba000;
  color: white;
  padding: 12px 24px;
  font-size: 20px;
  border-radius: 4px;
  border: none;
  margin-top: 50px;
  width: 25%;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border-radius: 10vh;
  position: relative;
}

.login__btn:hover {
  animation: bounce 0.5s linear forwards;
  background-color: #0062cc;
}

.about__btn:hover {
  background-color: #0062cc;
}

@keyframes bounce {
  0% {
    top: 0;
  }
  50% {
    top: -10px;
  }
  100% {
    top: 0;
  }
}

@media (max-width: 400px) {
  .login__btn {
    width: 70%;
    margin-top: 20px;
  }
}

.username__wrapper {
  display: flex;
  margin-bottom: 50px;
}

.wrapper {
  margin-bottom: 50px;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: rgba(115, 66, 214, 0.1);
  border: none;
  width: 25%;
  height: 50px;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  border-radius: 10vh;
  color: #3ba000;
  font-weight: bold;
}

.wrapper-wrap {
  justify-content: center;
  align-items: center;
  display: flex;
}

/* 
Calendar Page
*/

@media (max-width: 615px) {
  .img {
    width: 100%;
  }
}

/* SMALL PHONES, TABLETS, LARGE SMARTPHONES */
@media (max-width: 768px) {
  .header__description {
    padding: 0 24px;
  }

  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 26px;
  }

  button {
    font-size: 18px;
  }

  .book {
    width: 50%;
  }

  .book__selected {
    flex-direction: column;
  }
  .book__selected--figure {
    width: 100%;
    justify-content: flex-start;
  }
  .book__selected--description {
    width: 100%;
  }
}

/* SMALL PHONES, LARGE SMARTPHONES */
@media (max-width: 550px) {
  .header__img--wrapper {
    margin: 0 auto;
    transform: scale(0.8);
  }
  .btn__menu {
    display: block;
  }

  .nav_list {
    display: none;
  }

  .highlight {
    width: 100%;
  }

  .highlight:not(:last-child) {
    margin-bottom: 24px;
  }

  .cart__book--img {
    display: none;
  }
  .cart__book--info {
    padding-left: 0;
  }
}

@media only screen and (max-width: 400px) {
  .dash__links {
    width: 40%;
    /* or adjust the width to your preference */
  }
  .header__img--wrapper {
    margin: 0 auto;
    transform: scale(0.6);
  }
  .container {
    transform: scale(0.79);
  }
  .footer__link {
    margin: 0 auto;
    font-size: 19px;
  }

  .contact__btn--ctc {
    width: 40%;
  }
}

@media only screen and (max-width: 320px) {
  .header__img--wrapper {
    margin: 0 auto;
    transform: scale(0.4);
  }
}
/* Media queries */
@media only screen and (max-width: 768px) {
  .footer__list {
    flex-wrap: wrap;
    margin: 0 auto;
  }

  .footer__link {
    flex: 1 0 50%;
    margin-bottom: 20px;
  }

  .footer__logo--img {
    margin-top: 20px;
    width: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .footer__list {
    margin: 0 auto;
  }

  .footer__link {
    flex: 1 0 100%;
  }

  .footer__logo--img {
    width: 60px;
  }
}

@media only screen and (max-width: 480px) {
  .footer__list {
    margin: 0 auto;
  }

  .footer__link {
    flex: 1 0 100%;
  }

  .footer__logo--img {
    width: 60px;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  z-index: 1000;
}

.modal h2 {
  margin-top: 0;
}

.modal p {
  margin-bottom: 0;
}

button.modal-close {
  position: absolute;
  top: 5px;
  right: 5px;
  border: none;
  background-color: transparent;
  font-size: 24px;
  cursor: pointer;
}

@media print {
  /* hide buttons */
  button {
    display: none;
  }

  .calendar {
    height: auto !important;
    width: auto !important;
    position: static !important;
  }
  .event {
    position: static !important;
  }

  .fc-toolbar-chunk {
    display: none;
  }

  body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }

  #calendar-container {
    width: 100%;
    height: 100%;
    overflow: visible;
  }

  .fc {
    width: 100%;
    height: 100%;
    overflow: visible;
  }

  .fc-day-grid-container {
    width: 100%;
    height: 100%;
    overflow: visible;
  }

  .fc-day-grid {
    width: 100%;
    height: 100%;
    overflow: visible;
  }

  .fc-widget-header {
    width: 100%;
    height: 100%;
    overflow: visible;
  }

  .fc-row {
    width: 100%;
    height: 100%;
    overflow: visible;
  }
}

.test {
  padding: 15px;
}

.test__btn {
  margin: 0 auto;
  display: flex;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 10px;
}

.test__btn:hover {
  background-color: #0062cc;
  transition: 300ms;
}

.modal__title {
  font-size: 26px;
  margin-bottom: 13px;
}

.modal__description {
  font-size: 16px;
  padding-bottom: 10px;
}
